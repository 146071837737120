import React from 'react';
import { connect } from 'react-redux';
import UserListItem from './UserListItem';
import selectUsers from '../../selectors/users';

export const UserList = (props) => (    
    <div>
        <h2>List of users</h2>
        {props.users.map((user) => {
            return <UserListItem key={user.id} {...user} />
        } )}
    </div>
);

const mapStateToProps = (state) => {
    const userscontent = state.users;
    userscontent.sort(function(a, b) {
        if(a.fullname.toLowerCase() < b.fullname.toLowerCase()) return -1;
        if(a.fullname.toLowerCase() > b.fullname.toLowerCase()) return 1;
        return 0;
    })    
    return {
        users: selectUsers(userscontent, state.textfilters)        
    };
};

export default connect(mapStateToProps)(UserList);