import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AdminHeader from './AdminHeader';
import ProductForm from './ProductForm';
import {
  startAddUserProduct
} from '../../actions/userproducts';

const AddProductPage = (props) => {

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <AdminHeader />
      <main className="main-content">
        <div className="page-subheader">
          <div className="container">
            <div className="align-content">
              <h1>{props.user.fullname} - Add New Product</h1>
              <Link to={`/user-products/${props.user.id}`} className="link-button">
                <Button variant="contained" className="button">
                  Back
                </Button>
              </Link>
            </div>
          </div>
        </div>
        <div className="container">
          <ProductForm
            userproducts={props.userproducts}
            onSubmit={(userproducts) => {
              props.startAddUserProduct(props.user.id, userproducts);
              handleClick();
            }}
          />
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            autoHideDuration={5000}
            open={open}
            onClose={handleClose}
            className="snackbar-success"
            ContentProps={{
              'aria-describedby': 'message-id',
            }}
            message={
              <span id="message-id">
                <CheckCircleIcon />
                Product Added
              </span>
            }
          />
        </div>
      </main>
    </div>
  );

};

const mapStateToProps = (state, props) => {
  return {
    user: state.users.find((user) => user.id === props.match.params.id),
    userproducts: state.userproduct
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  startAddUserProduct: (id, userproducts) => {
    dispatch(startAddUserProduct(id, userproducts));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddProductPage);