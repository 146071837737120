import database from '../firebase/firebase';

// ADD_USER_ORDER
export const addUserOrder = (userorder) => ({
    type: 'ADD_USER_ORDER',
    userorder
});
  
  export const startAddUserOrder = (id, createdAt, items, notes) => {
    return (dispatch) => {
      const userorder = { createdAt, items, notes };  
      return database
        .ref(`orders/${id}/order`)
        .push(userorder)
        .then((ref) => {
          dispatch(
            addUserOrder({
              id: ref.key,
              ...userorder
            })
          );
        });
    };    
  };

// GET_ORDER_LIST
export const getOrderList = (userorder) => ({
    type: 'GET_ORDER_LIST',
    userorder
});
  
export const startGetOrderList = (id) => {
    return (dispatch) => {
        return database
        .ref(`orders/${id}/order`)
        .once('value')
        .then((snapshot) => {
            const userorder = [];

            snapshot.forEach((childSnapshot) => {
              userorder.push({
                id: childSnapshot.key,
                ...childSnapshot.val()
              });
            });

            dispatch(getOrderList(userorder));
        });
    };
};