import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import AppRouter from './routers/AppRouter';
import * as serviceWorker from './serviceWorker';
import configureStore from './store/configureStore';
import './styles/styles.scss';
import { startSetUsers } from './actions/users';
import { startSetCurrentUser } from './actions/auth';
import { startSetCart } from './actions/cart';

import { firebase } from './firebase/firebase';
import LoadingPage from './components/LoadingPage';
// import { database } from 'firebase';

const store = configureStore();

const jsx = (
  <Provider store={store}>
    <AppRouter />
  </Provider>
);

ReactDOM.render(<LoadingPage />, document.getElementById('root'));

firebase.auth().onAuthStateChanged((user) => {
  if (user) {
    // User is authenticated, fetch data and render the app
    store.dispatch(startSetCurrentUser(user.uid))
      .then(() => store.dispatch(startSetCart()))
      .then(() => store.dispatch(startSetUsers()))
      .then(() => {
        // Once data is loaded, render the main app
        ReactDOM.render(jsx, document.getElementById('root'));
      });
  } else {
    // User is not authenticated, you can choose to render a login page or show a message.
    // In this example, we'll render the main app with no data.
    ReactDOM.render(jsx, document.getElementById('root'));
  }
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
