// Auth reducer
const defaultAuthReducerState = {
  currentUser: undefined
};

export default (state = defaultAuthReducerState, action) => {
  switch (action.type) {
    case 'SET_CURRENT_USER':
      return {
        ...state,
        currentUser: action.user
      };
    case 'LOGOUT':
      return {
        ...state,
        currentUser: undefined
      };
    default:
      return state;
  }
};
