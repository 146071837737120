import database from '../firebase/firebase';

// SET_CURRENT_USER
export const setCurrentUser = (user) => ({
  type: 'SET_CURRENT_USER',
  user
});

export const startSetCurrentUser = (userId) => {
  return async (dispatch) => {
    const snapshot = await database.ref(`users/${userId}`).once('value');
    dispatch(
      setCurrentUser({
        uid: userId,
        email: snapshot.val().contactemail,
        name: snapshot.val().fullname,
        role: snapshot.val().role,
        logo: snapshot.val().logo,
        logoname: snapshot.val().logoname,
        bgcolor: snapshot.val().bgcolor,
        textcolor: snapshot.val().textcolor,
        contactname: snapshot.val().contactname,
        telephone: snapshot.val().contacttel,
        address: snapshot.val().deliveryaddress
      })
    );
  };
};

// LOGOUT
export const logout = () => ({
  type: 'LOGOUT'
});
