import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { firebase } from '../../firebase/firebase';
import { logout } from '../../actions/auth';
import Fab from '@material-ui/core/Fab';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import HomeIcon from '@material-ui/icons/Home';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';

const AdminHeader = ({ history, logout }) => {
  const handleLogout = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        logout();
        history.push('/');
      });
  };

  return (
    <div>
      <header className="header">
        <div className="container">
          <div className="align-content">
            <h1 className="header-title">Admin Dashboard</h1>
            <div>
              <Link to="/admin" className="header-button">
                <Fab aria-label="add" className="fab">
                  <HomeIcon />
                </Fab>
              </Link>
              <Link to="/media-library" className="header-button">
                <Fab aria-label="add" className="fab">
                  <PhotoLibraryIcon />
                </Fab>
              </Link>              
              <div className="header-button">
                <Fab color="secondary" aria-label="add" className="fab" onClick={handleLogout}>
                  <ExitToAppIcon />
                </Fab>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout())
});

export default connect(
  undefined,
  mapDispatchToProps
)(withRouter(AdminHeader));
