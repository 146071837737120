import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import PersonIcon from '@material-ui/icons/Person';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
    avatar: {
        margin: 10,
    },
    bigAvatar: {
        margin: 10,
        width: 60,
        height: 60,
    },
});

const UserListItem = ({ id, fullname, logo, role }) => {
    const classes = useStyles();

    return (

        <div className="user-list-item">
            <div className="user-info">
                {logo ? <Avatar alt={fullname} src={logo} className={classes.avatar} /> : <Avatar className={classes.avatar}><PersonIcon /></Avatar>}
                <div className="user-info-name">{fullname}</div>
            </div>
            <div>
                {role === 'user' ?
                    <Link to={`/user-products/${id}`} className="link-button button--margin-left">
                        <Button variant="contained" color="primary" className="button button-primary-preview">
                            View Products
                    </Button>
                    </Link>
                    : ''
                }
                <Link to={`/edituser/${id}`}  className="link-button button--margin-left">
                    <Button variant="contained" color="primary" className="button button-primary-edit">
                        Edit User
                    </Button>
                </Link>
            </div>
        </div>

    );

};

export default UserListItem;