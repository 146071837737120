import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import UserProductListItem from './UserProductListItem';
import selectUserProducts from '../../selectors/userproducts';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  card: {
    maxWidth: 345,
  },
  media: {
    height: 300
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  }
}));

export const UserProductList = (props) => {
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const HandlePreviewOpen = () => {
    setOpenModal(true);
  }

  const HandlePreviewClose = () => {
    setOpenModal(false);
  }

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <div>
      <div className="align-content">
        <h2>Product List</h2>
        <Button variant="contained" color="primary" className="button button-primary-preview" onClick={HandlePreviewOpen} disabled={props.userproducts.length === 0}>Preview</Button>
      </div>
      {props.userproducts.length === 0 ? <div className="m-t-b-30 text-center list-error">Currently no products</div> : ''}

      {props.userproduct.map((product) => {
        return <UserProductListItem key={product.productid} userid={props.userid} {...product} />;
      })}

      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={openModal}
        onClose={HandlePreviewClose}
      >
        <div className="preview-modal">
          <div className="header preview-header" style={props.user.bgcolor ? { backgroundColor: props.user.bgcolor } : { backgroundColor: '' }}>
            <div className="container">
              <div className="align-content">
                {props.user.logo ? <img className="header-logo" src={props.user.logo} alt={props.user.fullname} /> : <h1 className="header-title" style={props.user.textcolor ? { color: props.user.textcolor } : { color: '' }}>Hi {props.user.fullname}</h1>}
                <div>
                  <div className="header-button">
                    <Fab aria-label="add" className="fab" onClick={HandlePreviewClose}>
                      <CloseIcon />
                    </Fab>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="main-content b-c-light-grey">
            <div className="prod-list-container">
              <Grid container className={classes.root} spacing={2}>
                <Grid item xs={12}>
                  <Grid container justify="center" spacing={2}>
                    {props.userproducts.map((product) => {
                      return <Grid key={product.productid} item xs={6} md={3}>

                        <Card className={classes.card}>
                          <div className="prod-img">
                          <div className="prod-img-inner img-loaded" style={product.image ? { backgroundImage: `url(${product.image})` } : { backgroundImage: `url("/images/placeholder-img.jpg")`}}></div>
                          </div>
                          <div className="prod-title-block">
                            <CardHeader
                              title={product.title}
                            />
                            <IconButton
                              className={expanded ? classes.expandOpen : classes.expand}
                              onClick={handleExpandClick}
                              aria-expanded={expanded}
                              aria-label="show more"
                            >
                              <ExpandMoreIcon />
                            </IconButton>
                          </div>

                          <Collapse in={expanded} timeout="auto" unmountOnExit>
                            <CardContent>
                              <Typography paragraph>
                                {product.description}
                              </Typography>
                              {product.pdf ?
                                <a className="prod-pdf-example" href={product.pdf} download target="_blank" rel="noopener noreferrer">
                                  <Button className="button" startIcon={<PictureAsPdfIcon />}>Download Example</Button>
                                </a>
                                : ''}
                            </CardContent>
                          </Collapse>
                          <div className="prod-quantity-block">
                            <InputLabel id="quantity-select-label">Quantity</InputLabel>
                            <Select
                              labelId="quantity-select-label"
                              id="quantity-select"
                              value={product.quantity}
                              defaultValue={product.minquantity.split(',')[0]}
                            >
                              {
                                product.minquantity.split(',').map((selquantity) => {
                                  return <MenuItem key={selquantity} value={selquantity}>{selquantity}</MenuItem>
                                })
                              }
                            </Select>
                          </div>
                          <div className="prod-button-block">
                            <CardActions>
                              <Button
                                variant="contained"
                                color="primary"
                                className="button-primary add-cart-button"
                              >
                                Add to Cart
                            </Button>
                            </CardActions>
                          </div>
                        </Card>
                      </Grid>;
                    })}
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </Modal>


    </div>
  );
};

const mapStateToProps = (state, props) => {
  const userproductcontent = state.userproduct;
  userproductcontent.sort(function (a, b) {
    if (a.title.toLowerCase() < b.title.toLowerCase()) return -1;
    if (a.title.toLowerCase() > b.title.toLowerCase()) return 1;
    //if (a.productid.toLowerCase() < b.productid.toLowerCase()) return -1;
    //if (a.productid.toLowerCase() > b.productid.toLowerCase()) return 1;
    return 0;
  })
  return {
    user: state.users.find((user) => user.id === props.userid),
    userproduct: selectUserProducts(userproductcontent, state.textfilters)
  };
};

export default connect(mapStateToProps)(UserProductList);
