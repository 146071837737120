import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import Fab from '@material-ui/core/Fab';

import { firebase } from '../../firebase/firebase';

const storageRefImg = firebase.storage().ref("images");
const storageRefPdf = firebase.storage().ref("pdfs");

export default class ProductForm extends React.Component {
  
  constructor(props) {
    super(props);    

    //console.log('form props', props);

    this.state = {
      productid: props.userproducts ? props.userproducts.productid : '',
      title: props.userproducts ? props.userproducts.title : '',
      description: props.userproducts ? props.userproducts.description : '',
      minquantity: props.userproducts ? props.userproducts.minquantity : '',
      image: props.userproducts ? props.userproducts.image : '',
      imagename : props.userproducts ? props.userproducts.imagename : '',
      pdf: props.userproducts ? props.userproducts.pdf : '',
      pdfname : props.userproducts ? props.userproducts.pdfname : '',
      stock: props.userproducts ? props.userproducts.stock : 0,
      error: false,
      loading: false,
      success: false,
      loadingpdf: false,
      successpdf: false,
      imgmodalopen: false,
      prodImgList: [],
      prodImgListLen: 0,
      pdfmodalopen: false,
      prodPdfList: [],
      prodPdfListLen: 0
    }
    //this.selectProdImg = this.selectProdImg.bind(this);
    //this._selectProdImg = () => this.selectProdImg();
  }

  onProductIDChange = (e) => {
    const productid = e.target.value;
    this.setState(() => ({ productid }));
  }
  onTitleChange = (e) => {
    const title = e.target.value;
    this.setState(() => ({ title }));
  }
  onDescriptionChange = (e) => {
    const description = e.target.value;
    this.setState(() => ({ description }));
  }
  onMinquantityChange = (e) => {
    const minquantity = e.target.value;
    this.setState(() => ({ minquantity }));
  }
  onStockChange = (e) => {
    const stock = e.target.valueAsNumber;
    this.setState(() => ({ stock }));
  }
  onImageChange = (e) => {
    const image = e.target.value;
    this.setState(() => ({ image }));
  }
  onPdfChange = (e) => {
    const pdf = e.target.value;
    this.setState(() => ({ pdf }));
  }

  onSubmit = (e) => {
    e.preventDefault();

    if (!this.state.productid || !this.state.title || !this.state.minquantity) {
      this.setState(() => ({ error: true }));
    } else {
      this.setState(() => ({ error: false }));
      console.log('submitted');
      this.props.onSubmit({
        productid: this.state.productid,
        title: this.state.title,
        description: this.state.description,
        minquantity: this.state.minquantity,
        image: this.state.image,
        imagename: this.state.imagename,
        pdf: this.state.pdf,
        pdfname: this.state.pdfname,
        stock: this.state.stock
      });
    }
  };

  // image modal
  openImgModal = (e) => {
    e.preventDefault();
    this.setState(() => ({ imgmodalopen: true }));    
  };
  closeImgModal = (e) => {
    e.preventDefault();
    this.setState(() => ({ imgmodalopen: false }));
  };
  selectProdImg = (e) => {
    this.setState(() => ({ image:e.url, imagename:e.name, imgmodalopen: false }));
  };
  // clear image
  clearProdImg = (e) => {
    e.preventDefault();
    this.setState(() => ({ image:'', imagename:'' }));    
  };  

  // pdf modal
  openPdfModal = (e) => {
    e.preventDefault();
    this.setState(() => ({ pdfmodalopen: true }));    
  };
  closePdfModal = (e) => {
    e.preventDefault();
    this.setState(() => ({ pdfmodalopen: false }));
  };
  selectProdPdf = (e) => {
    //e.preventDefault();
    // console.log('e', e);
    // console.log('e url', e.url);
    // console.log('e url', e.name);
    this.setState(() => ({ pdf:e.url, pdfname:e.name, pdfmodalopen: false }));
  }; 
  // clear image
  clearProdPdf = (e) => {
    e.preventDefault();
    this.setState(() => ({ pdf:'', pdfname:'' }));    
  };    

  handleImagesLoad = () => {
    //const resultLen = '';
    let resultLen = this.state.prodImgList.length;  
    console.log('resultLen', resultLen);
    if(this.state.prodImgList.length > 0 && resultLen === this.state.prodImgList.length){
      console.log('img list NO CHANGE', this.state.prodImgList);
    }else{
      console.log('no img list / updated list');
      let imgArray = [...this.state.prodImgList];
      storageRefImg.listAll().then(function(result) {
        result.items.forEach(function(imageRef, i) {
          imageRef.getDownloadURL().then(function(url) { 
            imgArray[i] = {...imgArray[i], url: url, name: imageRef.name};
          }).catch(function(error) {
            // Handle any errors
          });
        });
      }).catch(function(error) {
        // Handle any errors
      });      
      this.setState(() => ({ prodImgList: imgArray }));
    }    
  };
  handlePdfsLoad = () => {
    //const resultLen = '';
    let resultLen = this.state.prodPdfList.length;  
    console.log('resultLen', resultLen);
    if(this.state.prodPdfList.length > 0 && resultLen === this.state.prodPdfList.length){
      console.log('img list NO CHANGE', this.state.prodPdfList);
    }else{
      console.log('no img list / updated list');
      let pdfArray = [...this.state.prodPdfList];
      storageRefPdf.listAll().then(function(result) {
        result.items.forEach(function(pdfRef, i) {
          pdfRef.getDownloadURL().then(function(url) { 
            pdfArray[i] = {...pdfArray[i], url: url, name: pdfRef.name};
          }).catch(function(error) {
            // Handle any errors
          });
        });
      }).catch(function(error) {
        // Handle any errors
      });      
      this.setState(() => ({ prodPdfList: pdfArray }));
    }    
  };  

	componentDidMount = () => {
    this.handleImagesLoad();
    this.handlePdfsLoad();
	}  

  /*

  no longer using this

  handleImageStart = () => {
    this.setState(() => ({ loading: true }));
  };
  handleImageProgress = (progress) => {
    // Paul - you can update the progress of the file upload here (the progress argument will be the percentage complete)
  };
  handleImageError = (e) => {
    console.log(e);
    alert('Image not uploaded')
  };
  handleImageSuccess = (filename) => {
    this.setState(() => ({ loading: false }));
    this.setState(() => ({ success: true }));
    firebase
      .storage()
      .ref('images')
      .child(filename)
      .getDownloadURL()
      .then((url) => {
        this.setState(() => ({
          image: url
        }));
      });
  };

  handlePdfStart = () => {
    this.setState(() => ({ loadingpdf: true }));
    // Paul - you can set the loading animation to start here
  };
  handlePdfProgress = (progress) => {
    // Paul - you can update the progress of the file upload here (the progress argument will be the percentage complete)
  };
  handlePdfError = (e) => {
    console.log(e);
    // Paul - you can show an error here
  };
  handlePdfSuccess = (filename) => {
    this.setState(() => ({ loadingpdf: false }));
    this.setState(() => ({ successpdf: true }));
    // Paul - you can stop the loading animation, set the isUploading flag to false, set progress to complete
    firebase
      .storage()
      .ref('pdfs')
      .child(filename)
      .getDownloadURL()
      .then((url) => {
        this.setState(() => ({
          pdf: url
        }));
      });
  };

  handleErrorClose = () => {
    this.setState(() => ({ error: false }));
  };
  */

  render() {
    return (
      <form className="form" onSubmit={this.onSubmit}>
        <div className="form-row">
          <TextField
            label="Product ID"
            className="textField"
            value={this.state.productid}
            onChange={this.onProductIDChange}
            margin="normal"
          />
          <TextField
            label="Title"
            className="textField"
            value={this.state.title}
            onChange={this.onTitleChange}
            margin="normal"
          />
        </div>
        <div className="form-row form-row-full">
          <TextField
            label="Description"
            className="textField"
            value={this.state.description}
            onChange={this.onDescriptionChange}
            margin="normal"
            multiline={true}
            rows={4}
            rowsMax={4}
          />
        </div>
        <div className="form-row">
          <TextField
            label="Quantity"
            className="textField"
            value={this.state.minquantity}
            onChange={this.onMinquantityChange}
            margin="normal"
          />
          <TextField
            label="Stock"
            className="textField"
            value={this.state.stock ? this.state.stock : 0}
            onChange={this.onStockChange}
            margin="normal"
            type="number"
          />
        </div>
        {/* Image select */}
        <div className="form-row m-t-b-50 form-select-media">
          <div className="MuiFormControl-root MuiTextField-root textField MuiFormControl-marginNormal">
            <Button
              variant="contained"
              color="primary"
              className="button button-primary-edit m-b-20"
              onClick={this.openImgModal}
            >
              Select Image
            </Button>
            <Button
              variant="contained"
              color="primary"
              className="button button-primary-pink"
              onClick={this.clearProdImg}
            >
              Clear Image
            </Button>
          </div> 
          <div className="MuiFormControl-root MuiTextField-root textField MuiFormControl-marginNormal">
            <div className="prodFormImg">
              {this.state.image &&
                <img src={this.state.image} alt={this.state.title} />
              }
              <span>{this.state.imagename}</span>                
            </div>  
          </div>    
        </div>
        <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={this.state.imgmodalopen}
        onClose={this.closeImgModal}
        >
        <div className="preview-modal">
          <div className="header preview-header">
            <div className="container">
              <div className="align-content">
                <div>
                  <div className="header-button">
                    <Fab aria-label="add" className="fab" onClick={this.closeImgModal}>
                      <CloseIcon />
                    </Fab>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="main-content b-c-light-grey">
            <div className="container media-library">
              {
                this.state.prodImgList.map((item, i) => {
                  return (
                    <div className="media-library-item" key={i}>
                      <div className="media-library-item-img" style={{ backgroundImage: "url("+item.url+")" }}></div>
                      <span className="media-item-name">{item.name}</span>
                      <button className="MuiButtonBase-root MuiButton-root MuiButton-contained button button-primary-edit MuiButton-containedPrimary" type="button" onClick={() => {this.selectProdImg(item)}}><span className="MuiButton-label">Select</span><span className="MuiTouchRipple-root"></span></button> 
                    </div>
                  ) 
                })
              }
            </div>
          </div>
        </div>          
        </Modal>

        <div className="divider"></div>
        {/* PDF select */}
        <div className="form-row m-t-b-50 form-select-media">
          <div className="MuiFormControl-root MuiTextField-root textField MuiFormControl-marginNormal">
            <Button
              variant="contained"
              color="primary"
              className="button button-primary-edit m-b-20"
              onClick={this.openPdfModal}
            >
              Select PDF
            </Button>
            <Button
              variant="contained"
              color="primary"
              className="button button-primary-pink"
              onClick={this.clearProdPdf}
            >
              Clear PDF
            </Button>
          </div> 
          <div className="MuiFormControl-root MuiTextField-root textField MuiFormControl-marginNormal">
            <div className="prodFormPdf">
              {this.state.pdf &&
                <PictureAsPdfIcon />              
              }              
              <span>{this.state.pdfname}</span>  
            </div>  
          </div>    
        </div>
        <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={this.state.pdfmodalopen}
        onClose={this.closePdfModal}
        >
        <div className="preview-modal">
          <div className="header preview-header">
            <div className="container">
              <div className="align-content">
                <div>
                  <div className="header-button">
                    <Fab aria-label="add" className="fab" onClick={this.closePdfModal}>
                      <CloseIcon />
                    </Fab>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="main-content b-c-light-grey">
            <div className="container media-library">
              {
                this.state.prodPdfList.map((item, i) => {
                  return (
                    <div className="media-library-item" key={i}>
                      <PictureAsPdfIcon />
                      <span className="media-item-name">{item.name}</span>
                      <button className="MuiButtonBase-root MuiButton-root MuiButton-contained button button-primary-edit MuiButton-containedPrimary" type="button" onClick={() => {this.selectProdPdf(item)}}><span className="MuiButton-label">Select</span><span className="MuiTouchRipple-root"></span></button> 
                    </div>
                  ) 
                })
              }
            </div>
          </div>
        </div>          
        </Modal>

        <div className="divider"></div>

        <div className="form-button-margin m-t-100">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className="button button-primary-edit"
          >
            Save Product
                    </Button>
        </div>
        <Dialog
          open={this.state.error}
          onClose={this.handleErrorClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="error-dialog"
        >
          <DialogTitle id="alert-dialog-title">{"New Product Error"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Please enter a product ID, title and minimum quantity to save product.
          </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleErrorClose} color="primary" autoFocus>
              OK
          </Button>
          </DialogActions>
        </Dialog>
      </form >
    )
  }
};