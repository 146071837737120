// Cart Reducer
const cartReducerDefaultState = {
  items: []
};

export default (state = cartReducerDefaultState, action) => {
  switch (action.type) {
    case 'SET_CART':
      return {
        ...state,
        items: action.cartItems
      };
    case 'UPDATE_CART':
      return {
        ...state,
        items: action.items
      };
    default:
      return state;
  }
};
