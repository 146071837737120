import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import DoneIcon from '@material-ui/icons/Done';
import SearchIcon from '@material-ui/icons/Search';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import Header from './Header';
import Footer from './Footer';

const AboutPage = () => {
    return (
        <div>
            <Header />
            <main className="main-content b-c-light-grey user-content-page">
                <div className="subheading bg-content bg-about">
                    <div className="container">
                        <h1>How does this work?<span className="subheading-border"></span></h1>
                    </div>
                </div>
                <div className="container">
                    <div className="text-center m-t-50">
                        <p>Making an order is as easy as 1.. 2.. 3..</p>
                        <div className="steps-container">
                            <div className="steps-item">
                                <div className="steps-number">1</div>
                                <div className="steps-icon"><SearchIcon fontSize="large" /></div>
                                <div className="steps-text">
                                    <p>Browse the products and add any to your cart.</p>
                                </div>
                            </div>
                            <div className="steps-item">
                                <div className="steps-number">2</div>
                                <div className="steps-icon"><ShoppingCartIcon fontSize="large" /></div>
                                <div className="steps-text">
                                    <p>Check your cart and if everything is correct click ORDER.</p>
                                </div>
                            </div>
                            <div className="steps-item">
                                <div className="steps-number">3</div>
                                <div className="steps-icon"><DoneIcon fontSize="large" /></div>
                                <div className="steps-text">
                                    <p>Once we have received your order we'll contact you to confirm and start printing your order! Delivery will take approx 5 working days.</p>
                                </div>
                            </div>
                        </div>
                        <div className="add-prod-arrow">
                            <ArrowDownwardIcon className="bounce" fontSize="large" />
                        </div>
                        <Link to="/" className="button-mail">
                            <Button
                            variant="contained"
                            color="primary"
                            className="button-primary button-large add-cart-button"
                            >
                            Get Started!
                            </Button>
                        </Link>
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    );
}

export default AboutPage;