import React, { useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { startAddToCart } from '../../actions/cart';

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 345
  },
  media: {
    height: 300
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  }
}));

const ProductItem = ({
  id,
  title,
  image,
  description,
  minquantity,
  pdf,
  addToCart,
  stock,
  productid
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [imgLoaded, setImgLoaded] = useState(true);
  const [quantity, setQuantity] = useState(minquantity.split(',')[0]);

  const handleAddSnackbar = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleQuantity = (e) => {
    setQuantity(e.target.value)
  };

  const handleAddToCart = (id, title, image, quantity, description, minquantity, productid) => {
    addToCart(id, title, image, quantity, description, minquantity, productid);
  };

  return (
    <Card className={classes.card}>
      <div className="prod-img">
        <div className={imgLoaded ? 'prod-img-inner img-loaded' : 'prod-img-inner img-loading'} style={image ? { backgroundImage: `url(${image})` } : { backgroundImage: `url("/images/placeholder-img.jpg")` }}></div>

      </div>
      <div className="prod-title-block">
        <CardHeader title={title} />
        <IconButton
          className={expanded ? classes.expandOpen : classes.expand}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
          disabled={!description && !pdf}
        >
          <ExpandMoreIcon />
        </IconButton>
      </div>

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography paragraph>{description}</Typography>
          {stock > 0 ? (
            <Typography paragraph className="stock-text">In Stock: {stock}</Typography>
          ) : (
            ''
          )}          
          {pdf ? (
            <a
              className="prod-pdf-example"
              href={pdf}
              download
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button className="button" startIcon={<PictureAsPdfIcon />}>
                Download Example
              </Button>
            </a>
          ) : (
              ''
            )}
            
        </CardContent>
      </Collapse>
      <div className="prod-quantity-block">
        <InputLabel id="quantity-select-label">Quantity</InputLabel>
        <Select
          labelId="quantity-select-label"
          id="quantity-select"
          value={quantity}
          onChange={handleQuantity}
          defaultValue={minquantity.split(',')[0]}
        >
          {
            minquantity.split(',').map((selquantity) => {
              return <MenuItem key={selquantity} value={selquantity}>{selquantity}</MenuItem>
            })
          }
        </Select>
      </div>
      <div className="prod-button-block">
        <CardActions>
          <Button
            variant="contained"
            color="primary"
            className="button-primary add-cart-button"
            onClick={() => {
              handleAddToCart(id, title, image, quantity, description, minquantity, productid);
              handleAddSnackbar();
            }}
          >
            Add to Cart
          </Button>
        </CardActions>
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        autoHideDuration={3000}
        open={open}
        onClose={handleClose}
        className="snackbar-success"
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={
          <span id="message-id">
            Added To Cart
          </span>
        }
      />
    </Card>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addToCart: (id, title, image, quantity, description, minquantity, productid) => {
      dispatch(startAddToCart(id, title, image, quantity, description, minquantity, productid));
    }
  };
};

export default connect(
  undefined,
  mapDispatchToProps
)(ProductItem);
