import React from 'react';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import AdminHeader from './AdminHeader';
import UserList from './UserList';
import UserListFilters from './UserListFilters';

const AdminDashboardPage = () => {

  return (
    <div>
      <AdminHeader />
      <main className="main-content">
        <div className="container ">
          <div className="align-content">
            <UserListFilters />
            <Link to="/adduser" className="link-button">
              <Button variant="contained" color="primary" className="button button-primary-add">
                Add new user
              </Button>
            </Link>
          </div>
          <div>

          </div>
          <div>
            <UserList />
          </div>
        </div>

      </main>

    </div>
  );
};

export default AdminDashboardPage;