import database from '../firebase/firebase';

// ADD_USER_PRODUCT
export const addUserProduct = (userproduct) => ({
  type: 'ADD_USER_PRODUCT',
  userproduct
});

export const startAddUserProduct = (id, userProductData = {}) => {
  return (dispatch) => {
    const {
      productid = '',
      title = '',
      description = '',
      minquantity = '',
      image = '',
      imagename = '',
      pdf = '',
      pdfname = '',
      stock = ''
    } = userProductData;
    const userproduct = { productid, title, description, minquantity, image, imagename, pdf, pdfname, stock };

    return database
      .ref(`products/${id}/product`)
      .push(userproduct)
      .then((ref) => {
        dispatch(
          addUserProduct({
            id: ref.key,
            ...userproduct
          })
        );
      });
  };
};

// REMOVE_USER_PRODUCT
export const removeUserProduct = (userid, id) => ({
  type: 'REMOVE_USER_PRODUCT',
  userid,
  id
});

export const startRemoveUserProduct = (userid, id) => {
  return (dispatch) => {
    return database
      .ref(`products/${userid}/product/${id}`)
      .remove()
      .then(() => {
        dispatch(removeUserProduct(userid, id));
      });
  };
};

// EDIT_USER_PRODUCT
export const editUserProduct = (userid, id, updates) => ({
  type: 'EDIT_USER_PRODUCT',
  userid,
  id,
  updates
});

export const startEditUserProduct = (userid, id, updates) => {
  return (dispatch) => {
    return database
      .ref(`products/${userid}/product/${id}`)
      .update(updates)
      .then(() => {
        dispatch(editUserProduct(userid, id, updates));
      });
  };
};

// GET_PRODUCT_LIST
export const getProductList = (userproduct) => ({
  type: 'GET_PRODUCT_LIST',
  userproduct
});

export const startGetProductList = (id) => {
  return (dispatch) => {
    return database
      .ref(`products/${id}/product`)
      .once('value')
      .then((snapshot) => {
        const userproduct = [];

        snapshot.forEach((childSnapshot) => {
          userproduct.push({
            id: childSnapshot.key,
            ...childSnapshot.val()
          });
        });

        dispatch(getProductList(userproduct));
      });
  };
};
