import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import AdminHeader from './AdminHeader';
import UserProductListFilters from './UserProductListFilters';
import UserProductList from './UserProductList';
import LoadingSpinner from '../LoadingSpinner';
import {
  startAddUserProduct,
  startGetProductList
} from '../../actions/userproducts';

const UserProductsPage = (props) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadProductList = async () => {
      await props.startGetProductList(props.user.id);
      setIsLoading(false);
    };

    loadProductList();
  }, []);

  return (
    <div>
      <AdminHeader />
      <main className="main-content">
        <div className="page-subheader">
          <div className="container">
            <div className="align-content">
              <h1>User Products - {props.user.fullname}</h1>
              <Link to="/admin" className="link-button">
                <Button variant="contained" className="button">
                  Back
              </Button>
              </Link>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="align-content">
            <UserProductListFilters />
            <Link to={`/add-product/${props.user.id}`} className="link-button">
              <Button variant="contained" color="primary" className="button button-primary-add">
                Add Product
              </Button>
            </Link>
          </div>
          <div>
            {isLoading ? <LoadingSpinner /> : <UserProductList userid={props.user.id} userproducts={props.userproducts} />}
          </div>
        </div>

      </main>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    user: state.users.find((user) => user.id === props.match.params.id),
    userproducts: state.userproduct,
    selectedUser: state.selectedUser
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  startGetProductList: (id) => {
    dispatch(startGetProductList(id));
  },
  startAddUserProduct: (id, userproducts) => {
    dispatch(startAddUserProduct(id, userproducts));
  }    
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserProductsPage);
