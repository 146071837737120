import firebase from 'firebase/app';
import 'firebase/auth';        // for authentication
import 'firebase/database'; // Firebase database
import 'firebase/storage';  // Firebase storage

const config = {
  apiKey: "AIzaSyCBev7zvmw5I4JYHwiEgoK_pOfhpgk5V34",
  authDomain: "kindredprint.firebaseapp.com",
  databaseURL: "https://kindredprint.firebaseio.com",
  projectId: "kindredprint",
  storageBucket: "kindredprint.appspot.com",
  messagingSenderId: "411891704874",
  appId: "1:411891704874:web:6970b11e616e3e071c0f53"
};

firebase.initializeApp(config);

// this is created to stop the user being signed in automatically to
// the newly created user - otherwise the admin would be immediately
// logged in as the user they just created
const firebaseAuth = firebase.initializeApp(config, 'auth');

const database = firebase.database();
// const googleAuthProvider = new firebase.auth.GoogleAuthProvider();

// firebase storage
const storage = firebase.storage();

export { firebase, firebaseAuth, storage, database as default };
