import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import Header from '../users/Header';
import Footer from '../users/Footer';
import {
  startRemoveFromCart,
  startEmptyCart,
  changeItemQuantity
} from '../../actions/cart';
import { startAddUserOrder } from '../../actions/orders';

const CartPage = (props) => {
	const [orderNotes, setOrderNotes] = useState('');
  const [openEmptyDialog, setOpenEmptyDialog] = useState(false);
  const [openSubmitDialog, setOpenSubmitDialog] = useState(false);
  const [openRemoveItem, setOpenRemoveItem] = useState(false);
	const [openOrderSubmit, setOpenOrderSubmit] = useState(false);
  const [openOrderMessage, setOpenOrderMessage] = useState(false);
  const [disableOrderButton, setdisableOrderButton] = useState(false);

  const handleChangeNotes = (e) => {
		setOrderNotes(e.target.value);
		console.log('orderNotes', orderNotes);
  } 

  const handleEmptyOpen = () => {
    setOpenEmptyDialog(true);
  };

  const handleEmptyClose = () => {
    setOpenEmptyDialog(false);
  };

  const handleSubmitOpen = () => {
    setOpenSubmitDialog(true);
  };

  const handleSubmitClose = () => {
    setOpenSubmitDialog(false);
  };

  const handleRemoveItemClose = () => {
    setOpenRemoveItem(false);
  };

  const handleOrderSubmitClose = () => {
    setOpenOrderSubmit(false);
  };

  const handleRemoveFromCart = (id) => {
    props.removeFromCart(id);
    setOpenRemoveItem(true);
  };

  const handleEmptyCart = () => {
    props.emptyCart();
    setOpenEmptyDialog(false);
  };

  const handleSubmitCart = async () => {
    setdisableOrderButton(true);
    try {
      await axios.post(
        'https://blacksquare-email-functions.herokuapp.com/send-order-email',
        {
          items: props.cart.items,
					user: props.auth.currentUser,
					notes: orderNotes
        }
      );
      props.addOrder(props.auth.currentUser.uid, Date.now(), props.cart.items, orderNotes);
      props.emptyCart();      
			setOpenOrderSubmit(true);
			setOpenOrderMessage(true);
      setOpenSubmitDialog(false);
      setdisableOrderButton(false);
    } catch (e) {
      console.log(e);
    }
    		
  };

  const handleChangeQuantity = (e, id) => {
    e.persist();
    props.changeItemQuantity(id, parseInt(e.target.value));
  };

  return (
    <div>
      <Header />
      <main className="main-content b-c-light-grey user-content-page">
        <div className="subheading bg-content bg-cart">
          <div className="container">
            <h1>Cart</h1>
          </div>
        </div>
        <div className="container">
					<div className="b-c-white">
            {props.cart.items.length === 0 ? (							
              <div>{openOrderMessage ? 'Your order has been sent. We will be in contact shortly. Your order should be with you within 5 working days.' : 'There are currently no items in your cart.'}</div>
            ) : (
                <>
                  <h2>Items in your cart</h2>
                  {props.cart.items.map((item) => (
                    <div className="cart-item" key={item.id}>
                      <div className="cart-info">
                        <div className="cart-info-image">
                          <div className="cart-info-image-inner" style={item.image ? { backgroundImage: `url(${item.image})` } : { backgroundImage: `url("/images/placeholder-img.jpg")`}}></div>
                        </div>
                        <div className="cart-info-content">
                          <div className="cart-info-title">{item.title}</div>
                          <div className="cart-info-description">
                            {item.description}
                          </div>
                        </div>
                      </div>
                      <div className="cart-quantity-block">
                        <InputLabel id="quantity-select-label">Quantity</InputLabel>
                        <Select
                          labelId="quantity-select-label"
                          id="quantity-select"
                          value={item.quantity}
                          onChange={(e) => handleChangeQuantity(e, item.id)}
                          defaultValue={item.minquantity.split(',')[0]}
                        >
                          {
                            item.minquantity.split(',').map((selquantity) => {
                              return <MenuItem key={selquantity} value={selquantity}>{selquantity}</MenuItem>
                            })
                          }
                        </Select>
                      </div>
                      <div>
                        <Button
                          variant="contained"
                          color="secondary"
                          className="button button-remove"
                          onClick={() => handleRemoveFromCart(item.id)}
                        >
                          Remove
                      </Button>
                      </div>
                    </div>
                  ))}

                  <div className="align-content">
										<div className="delivery-notes">
												<TextField												
													label="Delivery Notes"
													className="textField"
													value={orderNotes}
													onChange={(e) => handleChangeNotes(e)}
													margin="normal"
													multiline={true}
													rows={4}
													rowsMax={4}
												/>
										</div>
                  </div>

                  <div className="align-content cart-buttons m-t-100">
                    <Button
                      variant="contained"
                      color="secondary"
                      className="button button-remove"
                      onClick={handleEmptyOpen}
                    >
                      Empty cart
                    </Button>

                    <Button
                      variant="contained"
                      color="primary"
                      className="button button-order"
                      onClick={handleSubmitOpen}
                    >
                      Order
                  </Button>
                  </div>
                </>
              )}
          </div>
          <Dialog
            open={openEmptyDialog}
            onClose={handleEmptyClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{'Empty Cart?'}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to empty your cart?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleEmptyClose} color="primary">
                No
              </Button>
              <Button onClick={handleEmptyCart} color="secondary" autoFocus>
                Yes
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={openSubmitDialog}
            onClose={handleSubmitClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {'Confirm Order?'}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to confirm your order?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleSubmitClose} color="primary">
                No
              </Button>
              <Button onClick={handleSubmitCart} color="secondary" autoFocus disabled={disableOrderButton}>
                Yes
              </Button>
            </DialogActions>
          </Dialog>
          {disableOrderButton && <CircularProgress size={24} className="buttonProgress" />}
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
            autoHideDuration={2000}
            open={openRemoveItem}
            onClose={handleRemoveItemClose}
            className="snackbar-remove"
            ContentProps={{
              'aria-describedby': 'message-id'
            }}
            message={<span id="message-id">Item removed</span>}
          />
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
            autoHideDuration={5000}
            open={openOrderSubmit}
            onClose={handleOrderSubmitClose}
            className="snackbar-success"
            ContentProps={{
              'aria-describedby': 'message-id'
            }}
            message={<span id="message-id">Order sent</span>}
          />
        </div>
      </main>
      <Footer />
    </div>
  );
};

const mapStateToProps = ({ cart, auth }) => ({
  cart,
  auth
});

const mapDispatchToProps = (dispatch) => ({
  removeFromCart: (id) => {
    dispatch(startRemoveFromCart(id));
  },
  emptyCart: () => {
    dispatch(startEmptyCart());
  },
  changeItemQuantity: (id, quantity) => {
    dispatch(changeItemQuantity(id, quantity));
  },
  addOrder: (id, createdAt, items, notes) => {
    dispatch(startAddUserOrder(id, createdAt, items, notes));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CartPage));
