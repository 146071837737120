import React from 'react';

const Footer = () => {
  let newDate = new Date();
  let year = newDate.getFullYear();

  return (
    <footer className="footer">
      <div className="container">
        <div className="align-content">
          <div>
            <p>Black Square Print Media Ltd, <br></br>
              Graphic House, <br></br>
              Radford Way, <br></br>
              Billericay, <br></br>
              Essex, CM12 0DX
              <br></br>
              <br></br>
              01277 658444
              <br></br>
              <a href="mailto:info@blacksquarelitho.co.uk" target="_blank" rel="noopener noreferrer">info@blacksquarelitho.co.uk</a>
            </p>
          </div>
          <div><p>&copy; Copyright Black Square Print Media Ltd {year}</p></div>
        </div>


      </div>
    </footer>
  );
}

export default Footer;