import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import usersReducer from '../reducers/users';
import userProduct from '../reducers/userProduct';
import textFilters from '../reducers/textFilters';
import auth from '../reducers/auth';
import cartReducer from '../reducers/cart';
import userOrder from '../reducers/orders';
import thunk from 'redux-thunk';

const composeEnhancers = compose;

export default () => {
  const store = createStore(
    combineReducers({
      users: usersReducer,
      userproduct: userProduct,
      textfilters: textFilters,
      auth,
      cart: cartReducer,
      userorder: userOrder
    }),
    composeEnhancers(applyMiddleware(thunk))
  );

  return store;
};
