import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import Fab from '@material-ui/core/Fab';
import { firebase } from '../../firebase/firebase';

const storageRefImg = firebase.storage().ref("logos");

export default class UserForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      password: props.user ? props.user.password : '',
      bgcolor: props.user ? props.user.bgcolor : '',
      textcolor: props.user ? props.user.textcolor : '',
      logo: props.user ? props.user.logo : '',
      logoname: props.user ? props.user.logoname : '',
      role: props.user ? props.user.role : '',
      fullname: props.user ? props.user.fullname : '',
      contactname: props.user ? props.user.contactname : '',
      contacttel: props.user ? props.user.contacttel : '',
      contactemail: props.user ? props.user.contactemail : '',
      deliveryaddress: props.user ? props.user.deliveryaddress : '',
      error: false,
      loading: false,
      success: false,
      logomodalopen: false,
      logoImgList: [],
      logoImgListLen: 0
    };
  }
  onPasswordChange = (e) => {
    const password = e.target.value;
    this.setState(() => ({ password }));
  };
  onBgColorChange = (e) => {
    const bgcolor = e.target.value;
    this.setState(() => ({ bgcolor }));
  };
  onTextColorChange = (e) => {
    const textcolor = e.target.value;
    this.setState(() => ({ textcolor }));
  };
  onFullNameChange = (e) => {
    const fullname = e.target.value;
    this.setState(() => ({ fullname }));
  };
  onRoleChange = (e) => {
    const role = e.target.value;
    this.setState(() => ({ role }));
  };
  onContactNameChange = (e) => {
    const contactname = e.target.value;
    this.setState(() => ({ contactname }));
  };
  onContactTelChange = (e) => {
    const contacttel = e.target.value;
    this.setState(() => ({ contacttel }));
  };
  onContactEmailChange = (e) => {
    const contactemail = e.target.value;
    this.setState(() => ({ contactemail }));
  };
  onDeliveryAddressChange = (e) => {
    const deliveryaddress = e.target.value;
    this.setState(() => ({ deliveryaddress }));
  } 
  onLogoChange = (e) => {
    const logo = e.target.value;
    this.setState(() => ({ logo }));
  }  
  onSubmit = (e) => {
    e.preventDefault();

    if (
      (!this.props.isEdit && !this.state.password) ||
      !this.state.fullname
    ) {
      this.setState(() => ({
        error: true
      }));
    } else {
      this.setState(() => ({ error: false }));
      console.log('submitted');
      this.props.onSubmit({
        password: !this.props.isEdit ? this.state.password : '',
        bgcolor: this.state.bgcolor,
        textcolor: this.state.textcolor,
        logo: this.state.logo,
        logoname: this.state.logoname,
        role: this.state.role,
        fullname: this.state.fullname,
        contactname: this.state.contactname,
        contacttel: this.state.contacttel,
        contactemail: this.state.contactemail,
        deliveryaddress: this.state.deliveryaddress
      });
    }
  };
  handleResetPasswordClick = () => {
    firebase
      .auth()
      .sendPasswordResetEmail(this.state.contactemail)
      .then(() => {
        alert('Email sent');
      });
  };

  openImgModal = (e) => {
    e.preventDefault();
    this.setState(() => ({ logomodalopen: true }));
    
  };

  closeImgModal = (e) => {
    e.preventDefault();
    this.setState(() => ({ logomodalopen: false }));
  };

  selectLogoImg = (e) => {
    this.setState(() => ({ logo:e.url, logoname:e.name, logomodalopen: false }));
  };

  clearLogoImg = (e) => {
    this.setState(() => ({ logo:'', logoname:'' }));
  };  

  handleLogosLoad = () => {
    let resultLen = this.state.logoImgList.length;
    if(this.state.logoImgList.length > 0 && resultLen === this.state.logoImgList.length){
      console.log('img list NO CHANGE', this.state.logoImgList);
    }else{
      console.log('no img list / updated list');

      let imgArray = [...this.state.logoImgList];

      storageRefImg.listAll().then(function(result) {
        result.items.forEach(function(imageRef, i) {
          imageRef.getDownloadURL().then(function(url) { 
            imgArray[i] = {...imgArray[i], url: url, name: imageRef.name};
          }).catch(function(error) {
            // Handle any errors
          });
        });
      }).catch(function(error) {
        // Handle any errors
      });      
      this.setState(() => ({ logoImgList: imgArray }));
    }    
  };

	componentDidMount = () => {
		this.handleLogosLoad();
	}    

  render() {
    return (
      <form className="form" onSubmit={this.onSubmit}>
        <div className="form-row">
          {this.props.isEdit ? (
            <Button
              variant="contained"
              color="primary"
              className="button button-primary-edit form-half"
              onClick={this.handleResetPasswordClick}
            >
              Send password reset email
            </Button>
          ) : (
              <TextField
                label="Password"
                className="textField"
                value={this.state.password}
                onChange={this.onPasswordChange}
                margin="normal"
              />
            )}
        </div>
        <div className="form-row">
          <TextField
            label="Custom Background Color"
            className="textField"
            value={this.state.bgcolor}
            onChange={this.onBgColorChange}
            margin="normal"
          />
          <TextField
            label="Custom Text Color"
            className="textField"
            value={this.state.textcolor}
            onChange={this.onTextColorChange}
            margin="normal"
          />
        </div>
        <div className="form-row">
          <TextField
            label="Full Name"
            className="textField"
            value={this.state.fullname}
            onChange={this.onFullNameChange}
            margin="normal"
          />
          <TextField
            label="Contact Name"
            className="textField"
            value={this.state.contactname}
            onChange={this.onContactNameChange}
            margin="normal"
          />
        </div>
        <div className="form-row">
          <TextField
            label="Contact Telephone"
            className="textField"
            value={this.state.contacttel}
            onChange={this.onContactTelChange}
            margin="normal"
          />
          <TextField
            label="Contact Email"
            className="textField"
            value={this.state.contactemail}
            onChange={this.onContactEmailChange}
            margin="normal"
          />
        </div>
        <div className="form-row form-row-full">
          <TextField
            label="Delivery Address"
            className="textField"
            value={this.state.deliveryaddress}
            onChange={this.onDeliveryAddressChange}
            margin="normal"
            multiline={true}
            rows={4}
            rowsMax={4}
          />
        </div> 
        <div className="form-row m-t-50 form-select-media">
          <div className="MuiFormControl-root MuiTextField-root textField MuiFormControl-marginNormal">
            <Button
              variant="contained"
              color="primary"
              className="button button-primary-edit m-b-20"
              onClick={this.openImgModal}
            >
              Select Logo
            </Button>
            <Button
              variant="contained"
              color="primary"
              className="button button-primary-pink"
              onClick={this.clearLogoImg}
            >
              Clear Logo
            </Button>
          </div> 
          <div className="MuiFormControl-root MuiTextField-root textField MuiFormControl-marginNormal">
            <div className="prodFormImg">
              <img src={this.state.logo} alt={this.state.fullname} />
              <span>{this.state.logoname}</span>  
            </div>  
          </div>    
        </div>
        <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={this.state.logomodalopen}
        onClose={this.closeImgModal}
        >
        <div className="preview-modal">
          <div className="header preview-header">
            <div className="container">
              <div className="align-content">
                <div>
                  <div className="header-button">
                    <Fab aria-label="add" className="fab" onClick={this.closeImgModal}>
                      <CloseIcon />
                    </Fab>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="main-content b-c-light-grey">
            <div className="container media-library">
              {
                this.state.logoImgList.map((item, i) => {
                  return (
                    <div className="media-library-item" key={i}>
                      <img src={item.url} alt={item.name} />
                      <button className="MuiButtonBase-root MuiButton-root MuiButton-contained button button-primary-edit MuiButton-containedPrimary" type="button" onClick={() => {this.selectLogoImg(item)}}><span className="MuiButton-label">Select</span><span className="MuiTouchRipple-root"></span></button> 
                    </div>
                  ) 
                })
              }
            </div>
          </div>
        </div>          
        </Modal>                        
        <div className="form-row">
          <div className="form-half">
            <InputLabel htmlFor="user-role">Role</InputLabel>
            <Select
              value={this.state.role}
              onChange={this.onRoleChange}
              inputProps={{
                name: 'role',
                id: 'user-role'
              }}
            >
              <MenuItem value="user">User Role</MenuItem>
              <MenuItem value="admin">Admin Role</MenuItem>
            </Select>
          </div>
        </div>

        <div className="form-button-margin m-t-50">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className="button button-primary-edit"
          >
            Save User
          </Button>
        </div>
        <Dialog
          open={this.state.error}
          onClose={this.handleErrorClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="error-dialog"
        >
          <DialogTitle id="alert-dialog-title">{"New User Error"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Please provide a password and full name of user
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleErrorClose} color="primary" autoFocus>
              OK
          </Button>
          </DialogActions>
        </Dialog>
      </form>
    );
  }
}
