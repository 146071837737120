// User Product Reducer
const userProductReducerDefaultState = [];

export default (state = userProductReducerDefaultState, action) => {
  switch (action.type) {
    case 'GET_PRODUCT_LIST':
      return action.userproduct;
    case 'ADD_USER_PRODUCT':
      return [...state, action.userproduct];
    default:
      return state;
  }
};
