import React from 'react';
import TextField from '@material-ui/core/TextField';
import { connect } from 'react-redux';
import { setTextFilter } from '../../actions/textFilters';

const UserListFilters = (props) => (
    <div>
        <TextField
            label="Search Users"
            type="search"
            className="textField search-field"
            margin="normal"
            variant="outlined"
            value={props.textfilters.text}
            onChange={(e) => {
                props.dispatch(setTextFilter(e.target.value));
            }}
        />
    </div>
);

const mapStateToProps = (state) => {
    return {
        textfilters: state.textfilters
    };
};

export default connect(mapStateToProps)(UserListFilters);