import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import AdminHeader from './AdminHeader';
import CircularProgress from '@material-ui/core/CircularProgress';
import FileUploader from 'react-firebase-file-uploader';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
// import DeleteIcon from '@material-ui/icons/Delete';
// import DoneIcon from '@material-ui/icons/Done';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { firebase } from '../../firebase/firebase';

const MediaLibraryPage = () => {

  const storageRef = firebase.storage().ref("images");
  const storageRefLogos = firebase.storage().ref("logos");
  const storageRefPdfs = firebase.storage().ref("pdfs");

  //const mediaImages =  [, setMediaImages] = useState([]);
  const [prodError, setProdError] = useState(false);
  const [prodLoading, setProdLoading] = useState(false);
  const [prodSuccess, setProdSuccess] = useState(false);
  const [pdfError, setPdfError] = useState(false);
  const [pdfLoading, setPdfLoading] = useState(false);
  const [pdfSuccess, setPdfSuccess] = useState(false);
  const [logoError, setLogoError] = useState(false);
  const [logoLoading, setLogoLoading] = useState(false);
  const [logoSuccess, setLogoSuccess] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const [prodImgList, setProdImgList] = useState([]);
  const [prodImgCheck, setProdImgCheck] = useState(true);

  const [logoImgList, setLogoImgList] = useState([]);
  const [logoImgCheck, setLogoImgCheck] = useState(true);

  const [pdfList, setPdfList] = useState([]);
  const [pdfCheck, setPdfCheck] = useState(true);


  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    //handleImagesLoad();
  };

  // user product image upload
  const handleProdUploadStart = () => {
    setProdLoading(true);
  };
  const handleProdUploadError = (e) => {
    console.log(e);
    setProdError(true);
  };
  const handleProdUploadSuccess = (filename) => {
    setProdLoading(false);
    setProdSuccess(true);
    firebase
      .storage()
      .ref('images')
      .child(filename)
    setProdImgCheck(true);
    setProdImgList([]);
    handleImagesLoad();
    //setExpanded(false);
  };
  const handleProdUploadClose = () => {
    setProdSuccess(false);
    setProdError(false);
  };

  // user pdf upload
  const handlePdfUploadStart = () => {
    setPdfLoading(true);
  };
  const handlePdfUploadError = (e) => {
    console.log(e);
    setPdfError(true);
  };
  const handlePdfUploadSuccess = (filename) => {
    setPdfLoading(false);
    setPdfSuccess(true);
    firebase
      .storage()
      .ref('pdfs')
      .child(filename)
  };
  const handlePdfUploadClose = () => {
    setPdfSuccess(false);
    setPdfError(false);
  };


  // user logo upload
  const handleLogoUploadStart = () => {
    setLogoLoading(true);
  };
  const handleLogoUploadError = (e) => {
    console.log(e);
    setLogoError(true);
  };
  const handleLogoUploadSuccess = (filename) => {
    setLogoLoading(false);
    setLogoSuccess(true);
    firebase
      .storage()
      .ref('logos')
      .child(filename)
    window.location.reload(false);
  };
  const handleLogoUploadClose = () => {
    setLogoSuccess(false);
    setLogoError(false);
  };

  //const prodImgListLen = 0;

  // user prod image load
  const handleImagesLoad = () => {

    if (prodImgList.length > 0 && !prodImgCheck) {
      console.log('img list NO CHANGE', prodImgList.length);
    } else {
      console.log('no img list', prodImgList);
      //setProdImgList([]);

      storageRef.listAll().then(function (result) {
        result.items.forEach(function (imageRef) {
          imageRef.getDownloadURL().then(function (url) {
            setProdImgList(prodImgList => [...prodImgList, { url: url, name: imageRef.name }]);
          }).catch(function (error) {
            // Handle any errors
          });
        });
      }).catch(function (error) {
        // Handle any errors
      });
      setProdImgCheck(false);
    }
  };

  const deleteProdImg = (imgName) => {
    let delProdImg = firebase.storage().ref('images').child(imgName);
    delProdImg.delete().then(function () {
      // File deleted successfully
      alert('delete success');
      setProdImgCheck(true);
      setProdImgList([]);
      handleImagesLoad();
    }).catch(function (error) {
      // Uh-oh, an error occurred!
      alert('delete failed');
    });
  };

  // logo image load
  const handleLogosLoad = () => {

    if (logoImgList.length > 0 && !logoImgCheck) {
      console.log('logo img list NO CHANGE', logoImgList.length);
    } else {
      console.log('no logo img list', logoImgList);
      //setProdImgList([]);

      storageRefLogos.listAll().then(function (result) {
        result.items.forEach(function (imageRef) {
          imageRef.getDownloadURL().then(function (url) {
            setLogoImgList(logoImgList => [...logoImgList, { url: url, name: imageRef.name }]);
          }).catch(function (error) {
            // Handle any errors
          });
        });
      }).catch(function (error) {
        // Handle any errors
      });
      setLogoImgCheck(false);
    }
  };

  const deleteLogoImg = (imgName) => {
    let delLogoImg = firebase.storage().ref('logos').child(imgName);
    delLogoImg.delete().then(function () {
      // File deleted successfully
      alert('delete success');
      setLogoImgCheck(true);
      setLogoImgList([]);
      handleLogosLoad();
      window.location.reload(false);
    }).catch(function (error) {
      // Uh-oh, an error occurred!
      alert('delete failed');
    });
  };

  // pdf load
  const handlePdfsLoad = () => {
    if (pdfList.length > 0 && !pdfCheck) {
      console.log('pdf list NO CHANGE', pdfList.length);
    } else {
      console.log('no pdf list', pdfList);
      storageRefPdfs.listAll().then(function (result) {
        result.items.forEach(function (pdfRef) {
          pdfRef.getDownloadURL().then(function (url) {
            setPdfList(pdfList => [...pdfList, { url: url, name: pdfRef.name }]);
          }).catch(function (error) {
            // Handle any errors
          });
        });
      }).catch(function (error) {
        // Handle any errors
      });
      setPdfCheck(false);
    }
  };

  const deletePdf = (pdfName) => {
    let delPdf = firebase.storage().ref('pdfs').child(pdfName);
    delPdf.delete().then(function () {
      // File deleted successfully
      alert('delete success');
      setPdfCheck(true);
      setPdfList([]);
      handleLogosLoad();
      window.location.reload(false);
    }).catch(function (error) {
      // Uh-oh, an error occurred!
      alert('delete failed');
    });
  };

  useEffect(() => {
    // Your code here
    console.log('load images, icons, pdfs here');
    handleImagesLoad();
    handleLogosLoad();
    handlePdfsLoad();
  }, []);

  return (
    <div>
      <AdminHeader />
      <main className="main-content">
        <div className="page-subheader">
          <div className="container">
            <div className="align-content">
              <h1>Media Library</h1>
              <Link to="/admin" className="link-button">
                <Button variant="contained" className="button">
                  Back
                </Button>
              </Link>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="align-content">
            <p className="upload-message">Before uploading an image consider reducing file size using - <a href="https://tinypng.com" target="_blank" rel="noopener noreferrer">TinyPNG</a></p>
          </div>
        </div>
        <div className="container">
          <div className="align-content upload-button-container">

            <div className="button-container">
              <FileUploader
                accept="image/*"
                className="hidden-input"
                id="image-button-file"
                name="image"
                storageRef={firebase.storage().ref('images')}
                onUploadStart={handleProdUploadStart}
                onUploadError={handleProdUploadError}
                onUploadSuccess={handleProdUploadSuccess}
              />
              <label htmlFor="image-button-file">
                <Button
                  variant="contained"
                  component="span"
                  color="primary"
                  className="button button-primary-add"
                  startIcon={<PhotoCameraIcon />}
                  disabled={prodLoading}
                >
                  Add Product Image
                </Button>
                {prodLoading && <CircularProgress size={24} className="buttonProgress" />}
              </label>
            </div>
            <div className="button-container">
              <FileUploader
                accept=".pdf"
                className="hidden-input"
                id="pdf-button-file"
                name="pdf"
                storageRef={firebase.storage().ref('pdfs')}
                onUploadStart={handlePdfUploadStart}
                onUploadError={handlePdfUploadError}
                onUploadSuccess={handlePdfUploadSuccess}
              />
              <label htmlFor="pdf-button-file">
                <Button
                  variant="contained"
                  component="span"
                  color="primary"
                  className="button button-primary-add"
                  startIcon={<PictureAsPdfIcon />}
                  disabled={pdfLoading}
                >
                  Add Product PDF
                </Button>
                {pdfLoading && <CircularProgress size={24} className="buttonProgress" />}
              </label>
            </div>
            <div className="button-container">
              <FileUploader
                accept="image/*"
                className="hidden-input"
                id="logo-button-file"
                name="logo"
                storageRef={firebase.storage().ref('logos')}
                onUploadStart={handleLogoUploadStart}
                onUploadError={handleLogoUploadError}
                onUploadSuccess={handleLogoUploadSuccess}
              />
              <label htmlFor="logo-button-file">
                <Button
                  variant="contained"
                  component="span"
                  color="primary"
                  className="button button-primary-add"
                  startIcon={<PhotoCameraIcon />}
                  disabled={logoLoading}
                >
                  Add User Logo
              </Button>
                {logoLoading && <CircularProgress size={24} className="buttonProgress" />}
              </label>
            </div>

          </div>

        </div>
        <div className="container">
          <div className="faq-container">
            <ExpansionPanel expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography className="faq-heading">Product Images</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className="media-library-list">
                <div className="container media-library">
                  {
                    prodImgList.map(function (item, i) {
                      return (
                        <div className="media-library-item" key={i}>
                          <div className="media-library-item-img" style={{ backgroundImage: "url("+item.url+")" }}></div>
                          <span className="media-item-name">{item.name}</span>
                          <button className="MuiButtonBase-root MuiButton-root MuiButton-contained button button-primary-edit MuiButton-containedPrimary" type="button" onClick={() => { deleteProdImg(item.name) }}><span className="MuiButton-label">Delete</span><span className="MuiTouchRipple-root"></span></button>
                        </div>
                      )
                    })
                  }
                </div>

              </ExpansionPanelDetails>

            </ExpansionPanel>
            <ExpansionPanel expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <Typography className="faq-heading">Product PDFs</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <div className="container media-library">
                  {
                    pdfList.map(function (item, i) {
                      //console.log('test', item, i);
                      //<img src={item} key={i} alt="" />
                      return (
                        <div className="media-library-item" key={i}>
                          <PictureAsPdfIcon />
                          <span className="media-item-name">{item.name}</span>
                          <button className="MuiButtonBase-root MuiButton-root MuiButton-contained button button-primary-edit MuiButton-containedPrimary" type="button" onClick={() => { deletePdf(item.name) }}><span className="MuiButton-label">Delete</span><span className="MuiTouchRipple-root"></span></button>
                        </div>
                      )

                    })
                  }
                </div>
              </ExpansionPanelDetails>

            </ExpansionPanel>
            <ExpansionPanel expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
              >
                <Typography className="faq-heading">User Logos</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <div className="container media-library">
                  {
                    logoImgList.map(function (item, i) {
                      //console.log('test', item, i);
                      //<img src={item} key={i} alt="" />
                      return (
                        <div className="media-library-item" key={i}>
                          <div className="media-library-item-img" style={{ backgroundImage: "url("+item.url+")" }}></div>
                          <span className="media-item-name">{item.name}</span>
                          <button className="MuiButtonBase-root MuiButton-root MuiButton-contained button button-primary-edit MuiButton-containedPrimary" type="button" onClick={() => { deleteLogoImg(item.name) }}><span className="MuiButton-label">Delete</span><span className="MuiTouchRipple-root"></span></button>
                        </div>
                      )

                    })
                  }
                </div>
              </ExpansionPanelDetails>

            </ExpansionPanel>
          </div>
        </div>

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          autoHideDuration={2000}
          open={prodSuccess}
          onClose={handleProdUploadClose}
          className="snackbar-success"
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={
            <span id="message-id">
              <CheckCircleIcon />
              Product Image Added
            </span>
          }
        />
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          autoHideDuration={2000}
          open={prodError}
          onClose={handleProdUploadClose}
          className="snackbar-success snackbar-error"
          ContentProps={{
            'aria-describedby': 'message-id-error',
          }}
          message={
            <span id="message-id-error">
              Upload Error
            </span>
          }
        />
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          autoHideDuration={2000}
          open={pdfSuccess}
          onClose={handlePdfUploadClose}
          className="snackbar-success"
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={
            <span id="message-id">
              <CheckCircleIcon />
              Product PDF Added
            </span>
          }
        />
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          autoHideDuration={2000}
          open={pdfError}
          onClose={handlePdfUploadClose}
          className="snackbar-success snackbar-error"
          ContentProps={{
            'aria-describedby': 'message-id-error',
          }}
          message={
            <span id="message-id-error">
              Upload Error
            </span>
          }
        />
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          autoHideDuration={2000}
          open={logoSuccess}
          onClose={handleLogoUploadClose}
          className="snackbar-success"
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={
            <span id="message-id">
              <CheckCircleIcon />
              User Logo Added
            </span>
          }
        />
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          autoHideDuration={2000}
          open={logoError}
          onClose={handleLogoUploadClose}
          className="snackbar-success snackbar-error"
          ContentProps={{
            'aria-describedby': 'message-id-error',
          }}
          message={
            <span id="message-id-error">
              Upload Error
            </span>
          }
        />
      </main>
    </div>
  )
}

export default MediaLibraryPage;
