import React from 'react';
import Header from './Header';
import Footer from './Footer';

const PrivacyPage = () => {
    return (
        <div>
            <Header />
            <main className="main-content b-c-light-grey user-content-page">
                <div className="subheading bg-content bg-privacy">
                    <div className="container">
                        <h1>Privacy Page<span className="subheading-border"></span></h1>
                    </div>
                </div>
                <div className="container">
                    <div className="b-c-white">
                    <p><strong>Black Square Print Media Ltd Privacy &amp; Cookie Policy</strong></p>
                    <p>Your Privacy is Important to us. This notice informs you how we use the information you give us and how we protect your privacy. It also explains your rights under the UK Data Protection Act (update 2018) and the EU General Data Protection Regulations 2018. We abide by UK Law and our data protection regulator within the UK is the Information Commissioners Office (details below).</p>
                    <p>&raquo; <a href="http://blacksquarelitho.co.uk/privacy-policy.php#PersonalInfo" target="_blank" rel="noopener noreferrer">Use of Your Personal Information</a><br /> &raquo; <a href="http://blacksquarelitho.co.uk/privacy-policy.php#Disclosure" target="_blank" rel="noopener noreferrer">Disclosure of Your Information</a><br /> &raquo; <a href="http://blacksquarelitho.co.uk/privacy-policy.php#Cookies" target="_blank" rel="noopener noreferrer">Cookies</a><br /> &raquo; <a href="http://blacksquarelitho.co.uk/privacy-policy.php#Security" target="_blank" rel="noopener noreferrer">Security</a><br /> &raquo; <a href="http://blacksquarelitho.co.uk/privacy-policy.php#General" target="_blank" rel="noopener noreferrer">General</a></p>
                    <ol>
                    <li><strong> Use of Your Personal Information</strong></li>
                    </ol>
                    <p>We offer design, print and publishing for our clients. To provide such services and products, we may need to collect personal data such as name, address, phone and email details.</p>
                    <p>We will store this data and hold it on computers or otherwise. We will use this data to fulfil our agreement with you.</p>
                    <p>We may use information that you provide or that is obtained by us: to introduce you to the services we offer, to help with ongoing administration, in pursuance of a contract, to contact you with any review or changes that are applicable, for our own business research and analysis . We may keep you informed of such products and services (including special offers, discounts, offers, competitions and so on, including products and services of other companies and organisations which we consider may be of interest to you) by any of the following methods:</p>
                    <ul>
                    <li>e-mail</li>
                    <li>telephone (including automated calls)</li>
                    <li>post</li>
                    <li>fax</li>
                    <li>or otherwise (including products and services of other companies and organisations) which we consider may be of interest to you</li>
                    </ul>
                    <p>Withholding of the required data may result in an insufficient service being provided. We may obtain data from individual customers or from employer firms.</p>
                    <p>If you want to be removed from our database for these purposes, please email with the word "unsubscribe" in the subject line. Please also include your name and the email address that you wish to be removed. We will action your request as quickly as possible.</p>
                    <p><strong>Your rights regarding your data:</strong></p>
                    <ul>
                    <li>We will not hold your information for any longer than is necessary and as a maximum no longer than 2 after you have terminated as a client of our firm unless there is a lawful basis for retention</li>
                    <li>Should your data be inaccurate we will rectify this without delay</li>
                    <li>Should you wish us to delete your data at any point, please contact the office as shown further below to make the request. This will be actioned unless there is a lawful basis for us to retain the information in which case you will be advised accordingly</li>
                    <li>Should you wish to transfer your data to another organisation, please contact the office as below and we will organise this for you</li>
                    <li>You can request access to your data at any time. We will provide you with a full copy within one month of your request</li>
                    <li>You can withdraw consent to us holding and processing your information at any time. Please contact the office as below should you wish to do this</li>
                    <li>We may provide you with updates and bulletins which are appropriate to your service on an ongoing basis &ndash; this will be explained to you if it is applicable. You can opt out of these should you wish to &ndash; contact us and advise us if you wish to</li>
                    </ul>
                    <ol start="2">
                    <li><strong> Disclosure of Your Information</strong></li>
                    </ol>
                    <p>We may share your personal data with the following organisations:</p>
                    <ul>
                    <li>We may verify your identity by searching publicly available records</li>
                    <li>Business partners, suppliers and sub-contractors for the performance of any contract we enter into with them</li>
                    </ul>
                    <p>We will not sell or share your information with any other third parties unless we have obtained additional consent from you.</p>
                    <ol start="3">
                    <li><strong> Cookies</strong></li>
                    </ol>
                    <p>A cookie is an element of data that a website can send to your browser, which may then store itself onto your computer.</p>
                    <p>This website uses cookies, by using our website you agree that we can place cookies on your computer. You can usually modify your browser settings to prevent this happening however by disabling cookies, or certain types of cookie, you may hinder your user experience on this and other websites, or prevent them from working entirely.</p>
                    <p>Some cookies used on this website are "essential" in order to enable you to move around the website and use its features, such as accessing secure areas of the website. Without these cookies, services you have asked for, like shopping baskets or e-billing, cannot be provided.</p>
                    <p>We also use "non-essential" cookies, these cookies can be used to remember changes you have made such as text size, fonts and other parts of web pages that you can customise. They may also be used to provide services you have asked for such as watching a video or commenting on a blog.</p>
                    <p>Please note that some cookies may be placed by third-party service providers such as Google Analytics, a popular web analytics service provided by Google. This web site uses Google Analytics to help us to analyse how visitors use the site. You can find out more about how these cookies are used on the Google Privacy site.</p>
                    <p>Other third-party cookies used on this website include social bookmarking tools (such as LinkedIn Follow button, Facebook Like Button, Twitter Follow button, Google +1 button) and YouTube videos etc. Some of these services may be used to track your behaviour on other websites and we have no control over this.</p>
                    <p>Please note: We only use cookie information for functionality and statistical analysis purposes and in no way gives us access to your computer or any personal information about you.</p>
                    <p>Please note that some cookies may be placed by third-party service providers such as Google Analytics, a popular web analytics service provided by Google. This web site uses Google Analytics to help us to analyse how visitors use the site. You can find out more about how these cookies are used on the <a href="https://support.google.com/analytics/answer/6004245" target="_blank" rel="noopener noreferrer">Google Privacy</a> site.</p>
                    <p>Other third-party cookies used on this website include social bookmarking tools (such as LinkedIn Follow button, Facebook Like Button, Twitter Follow button, Google +1 button) and YouTube videos etc. Some of these services may be used to track your behaviour on other websites and we have no control over this.</p>
                    <p>Please note: We only use cookie information for functionality and statistical analysis purposes and in no way gives us access to your computer or any personal information about you.</p>
                    <ol start="4">
                    <li><strong> Security</strong></li>
                    </ol>
                    <p>We have in place a full information security policy, business continuity and disaster recovery plans. We take data security very seriously and have in place we feel the most secure options available to store and transfer your data securely. We can provide details on request.</p>
                    <p>We will treat all of your information in strict confidence and we will endeavour to take all reasonable steps to protect your personal information. However, we cannot guarantee the security of any data you disclose online. You accept the inherent security risks of providing information and dealing online over the Internet and will not hold us responsible for any breach of security unless this is due to our negligence or wilful default.</p>
                    <p>You should not submit any sensitive or private information through this website except through acknowledged secure areas. The owners of this website accept no responsibility for data submitted through insecure areas of this website.</p>
                    <p>Your browser generates other information, including which language the site is displayed in, and your Internet Protocol address ("IP address"). An IP address is a set of numbers which is assigned to your computer during a browsing session whenever you log on to the Internet via your internet service provider or your network (if you access the internet from, for example, a computer at work). Your IP address is automatically logged by our servers and used to collect traffic data about visitors to our websites. We do not use your IP address to identify you personally.</p>
                    <p>Security and possibility of international transfer - Please note that countries outside the EEA may not have the same level of protection as countries within the EEA, but we always demand that those parties to whom we transfer data adhere to the same security procedures that we follow ourselves. By submitting your personal information, you consent to such processing and transfers in connection with such orders, offers of services and for any other purposes to which you consent at the time you provide the information.</p>
                    <p>Protection against cyber-attacks/hackers - We have a firewall on our web server which is designed to inspect incoming traffic, weed out any malicious attacks and provides a first line of defence between a potential hacker and the data that we are hosting.</p>
                    <p>Information you send us via email - If you enter into any correspondence via email to report a problem or ask a question, then we will retain the personal information you send us for the purposes of dealing with your enquiry and will not share the data with any third parties.</p>
                    <p>Data encryption - Our website uses an SSL certificate which makes sure that there is an encrypted connection between the web browser and the server. This means that any personal information that's entered on the website is encrypted when being transferred to the web server.</p>
                    <ol start="5">
                    <li><strong> General</strong></li>
                    </ol>
                    <p>Should you wish to contact us in relation to any issues within this notice, please contact:</p>
                    <p><em>Adam Young<br /> Data Controller<br /> Black Square Print Media Ltd<br /> Graphic House<br /> Radford Way<br /> Billericay<br /> Essex<br /> CM12 0DX </em></p>
                    <p>You will be responded to as promptly as possible.</p>
                    <p>If you are concerned that any of the information we hold on you is incorrect or you have a general enquiry in connection with our Privacy policy, please <a href="http://blacksquarelitho.co.uk/cpntact.php" target="_blank" rel="noopener noreferrer">contact us</a>.</p>
                    <p>Please be aware that our website may link to other websites which may be accessed through our site. We are not responsible for the data policies or procedures or the content of these linked websites.</p>
                    <p><strong>Your right to complain:</strong></p>
                    <p>Should you wish to make a complaint in relation to data protection issues relating to our firm, you can contact us as above or you can contact our regulator as follows: The Information Commissioner&rsquo;s Office Wycliffe House, Water Lane, Wilmslow, Cheshire, SK9 5AF Tel: 0303 123 1113 (local rate) or 01625 545 745, if you prefer to use a national rate number <a href="http://blacksquarelitho.co.uk/www.ico.org.uk" target="_blank" rel="noopener noreferrer">www.ico.org.uk</a></p>
                    <p><strong>Consent</strong></p>
                    <p>We are required under the Regulations to obtain your consent to our collection, processing, sharing and holding of your personal data. Please ensure you have read this notice fully and accept all that it contains. If you have any queries, please contact us directly. Please contact us should you not be able to read or understand this notice sufficiently.</p>
                    </div>
                </div>
            </main>
            <Footer />            
        </div>
    );
}

export default PrivacyPage;