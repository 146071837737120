import React from 'react';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import AdminHeader from './AdminHeader';
import UserForm from './UserForm';
import { startAddUser } from '../../actions/users';
import { firebaseAuth } from '../../firebase/firebase';

export class AddUserPage extends React.Component {
  onSubmit = async (user) => {
    const newUser = await firebaseAuth
      .auth()
      .createUserWithEmailAndPassword(user.contactemail, user.password);

    await this.props.startAddUser(user, newUser.user.uid);
    firebaseAuth.auth().signOut();
    this.props.history.push('/admin');
  };
  render() {
    return (
      <div>
        <AdminHeader />
        <main className="main-content">
          <div className="page-subheader">
            <div className="container">
              <div className="align-content">
                <h1>Add New User</h1>
                <Link to="/admin" className="link-button">
                  <Button variant="contained" className="button">
                    Back
                    </Button>
                </Link>
              </div>
            </div>
          </div>
          <div className="container">
            <UserForm
              onSubmit={this.onSubmit}
            />
          </div>
        </main>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  startAddUser: (user, userId) => dispatch(startAddUser(user, userId))
});

export default connect(
  undefined,
  mapDispatchToProps
)(AddUserPage);
