import React from 'react';

const MaintenancePage = () => (
  <div className="maintenance-container">
    <div className="maintenance-content">
      <p><strong>Maintenance in progress</strong> <br></br><br></br>We apologise for the inconvenience. Our website will be back up shortly.</p>
    </div>
    
  </div>
);

export default MaintenancePage;