import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import ProductItem from './ProductItem';
import selectUserProducts from '../../selectors/userproducts';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
}));

const ProductList = (props) => {
  const classes = useStyles();
  
  return (
    <div className="prod-list-container">
      <Grid container className={classes.root} spacing={2}>
        <Grid item xs={12}>
          <Grid container justify="center" spacing={2}>
            {props.userproduct.map((product) => {
              return <Grid key={product.productid} item xs={12} sm={6} md={3}><ProductItem  {...product} /></Grid>;
            })}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => {
  const userproductcontent = state.userproduct;
  userproductcontent.sort(function(a, b) {
      if(a.title.toLowerCase() < b.title.toLowerCase()) return -1;
      if(a.title.toLowerCase() > b.title.toLowerCase()) return 1;
      return 0;
  })  
  return {
    userproduct: selectUserProducts(userproductcontent, state.textfilters)
  };
};

export default connect(mapStateToProps)(ProductList);
