// SET_CART
export const setCart = (cartItems) => ({
  type: 'SET_CART',
  cartItems
});

export const startSetCart = () => {
  return (dispatch) => {
    const cartItems = JSON.parse(localStorage.getItem('cart')) || [];
    dispatch(setCart(cartItems));
  };
};

// UPDATE_CART
export const updateCart = (items) => ({
  type: 'UPDATE_CART',
  items
});

export const startAddToCart = (id, title, image, quantity, description, minquantity, productid) => {
  return (dispatch) => {
    const cartItems = JSON.parse(localStorage.getItem('cart')) || [];
    const itemExists = cartItems.find((item) => item.id === id);

    let newItems = [];

    if (itemExists) {
      newItems = cartItems.map((item) => {
        if (item.id === itemExists.id) {
          return {
            ...item,
            quantity: quantity
          };
        }
        return item;
      });
    } else {
      newItems = [
        ...cartItems,
        {
          id,
          title,
          image,
          quantity,
          description,
          minquantity,
          productid
        }
      ];
    }

    localStorage.setItem('cart', JSON.stringify(newItems));

    dispatch(updateCart(newItems));
  };
};

export const startRemoveFromCart = (id) => {
  return (dispatch) => {
    const cartItems = JSON.parse(localStorage.getItem('cart')) || [];
    const newItems = cartItems.filter((item) => item.id !== id);

    localStorage.setItem('cart', JSON.stringify(newItems));
    dispatch(updateCart(newItems));
  };
};

export const startEmptyCart = () => {
  return (dispatch) => {
    localStorage.setItem('cart', JSON.stringify([]));
    dispatch(updateCart([]));
  };
};

export const changeItemQuantity = (id, quantity) => {
  return (dispatch) => {
    const cartItems = JSON.parse(localStorage.getItem('cart')) || [];
    const itemToUpdate = cartItems.find((item) => item.id === id);

    if (!itemToUpdate) {
      return;
    }

    const newItems = cartItems.map((item) => {
      if (item.id === itemToUpdate.id) {
        return {
          ...item,
          quantity
        };
      }
      return item;
    });

    localStorage.setItem('cart', JSON.stringify(newItems));
    dispatch(updateCart(newItems));
  };
};
