import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { firebase } from '../../firebase/firebase';
import { logout } from '../../actions/auth';
import Badge from '@material-ui/core/Badge';
import Drawer from '@material-ui/core/Drawer';
import Fab from '@material-ui/core/Fab';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import HomeIcon from '@material-ui/icons/Home';
import InfoIcon from '@material-ui/icons/Info';
import LockIcon from '@material-ui/icons/Lock';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import StarIcon from '@material-ui/icons/Star';

const Header = ({ history, logout, auth, cart }) => {
  const [openDrawer, setOpenDrawer] = useState(false);

  const toggleDrawer = (status) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setOpenDrawer(status);
  };

  const handleHome = () => {
    history.push('/');
  };

  const handleCart = () => {
    history.push('/cart');
  };

  const handleAbout = () => {
    history.push('/how-does-this-work');
  };

  const handleContact = () => {
    history.push('/contact');
  };

  const handleNewProduct = () => {
    history.push('/new-product');
  };

  const handleMyAccount = () => {
    history.push('/my-account');
  };

  const handlePrivacy = () => {
    history.push('/privacy');
  };

  // const handleTerms = () => {
  //   history.push('/terms');
  // };

  const handleLogout = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        logout();
        history.push('/');
      });
  };

  return (
    <header
      className="header"
      style={
        auth.currentUser.bgcolor
          ? { backgroundColor: auth.currentUser.bgcolor }
          : { backgroundColor: '' }
      }
    >
      <div className="container">
        <div className="align-content">
          {auth.currentUser.logo ? (
            <img
              className="header-logo"
              src={auth.currentUser.logo}
              alt={auth.currentUser.name}
            />
          ) : (
            <h1
              className="header-title"
              style={
                auth.currentUser.textcolor
                  ? { color: auth.currentUser.textcolor }
                  : { color: '' }
              }
            >
              Hi {auth.currentUser.name}
            </h1>
          )}
          <div>
            <Link to="/" className="header-button">
              <Fab aria-label="add" className="fab">
                <HomeIcon />
              </Fab>
            </Link>
            <Link to="/cart" className="header-button">
              <Fab aria-label="add" className="fab">
                <Badge badgeContent={cart.items.length} color="primary">
                  <ShoppingCartIcon />
                </Badge>
              </Fab>
            </Link>
            <div className="header-button">
              <Fab
                aria-label="add"
                className="fab"
                onClick={toggleDrawer(true)}
              >
                <MoreVertIcon />
              </Fab>
            </div>
          </div>
        </div>
      </div>
      <Drawer anchor="right" open={openDrawer} onClose={toggleDrawer(false)}>
        <div
          className="menu-list"
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <List>
            <ListItem button key="Home" onClick={handleHome}>
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary="Home" />
            </ListItem>
            <ListItem button key="Cart" onClick={handleCart}>
              <ListItemIcon>
                <ShoppingCartIcon />
              </ListItemIcon>
              <ListItemText primary="Cart" />
            </ListItem>
            <ListItem button key="How does this work?" onClick={handleAbout}>
              <ListItemIcon>
                <InfoIcon />
              </ListItemIcon>
              <ListItemText primary="How does this work?" />
            </ListItem>
            <ListItem button key="Contact" onClick={handleContact}>
              <ListItemIcon>
                <ContactSupportIcon />
              </ListItemIcon>
              <ListItemText primary="Contact Us" />
            </ListItem>
            <ListItem button key="NewProduct" onClick={handleNewProduct}>
              <ListItemIcon>
                <StarIcon />
              </ListItemIcon>
              <ListItemText primary="New Product" />
            </ListItem>
            <ListItem button key="My Account" onClick={handleMyAccount}>
              <ListItemIcon>
                <AccountCircleIcon />
              </ListItemIcon>
              <ListItemText primary="My Account" />
            </ListItem>
            <ListItem button key="Privacy" onClick={handlePrivacy}>
              <ListItemIcon>
                <LockIcon />
              </ListItemIcon>
              <ListItemText primary="Privacy" />
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem button key="Logout" onClick={handleLogout}>
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItem>
          </List>
        </div>
      </Drawer>
    </header>
  );
};

const mapStateToProps = (state, props) => {
  return {
    auth: state.auth,
    cart: state.cart
  };
};

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Header));
