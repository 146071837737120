import React from 'react';
import Header from './Header';
import Footer from './Footer';

const TermsPage = () => {
    return (
        <div>
            <Header />
            <main className="main-content b-c-light-grey user-content-page">
                <div className="subheading bg-content bg-terms">
                    <div className="container">
                        <h1>Terms &amp; Conditions<span className="subheading-border"></span></h1>
                    </div>
                </div>
                <div className="container">
                    <div className="b-c-white">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean risus turpis, eleifend vel malesuada quis, blandit nec mauris. Morbi elit risus, sollicitudin a leo bibendum, porttitor interdum neque. Mauris ultricies ac nisi ut cursus. Cras quis lectus ac metus malesuada consectetur. Donec tempus sodales dui eu molestie. Praesent pellentesque odio eget dolor lacinia, a viverra lacus pellentesque. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Nullam non sem enim. Vivamus id eros dictum, mollis purus non, aliquam erat. Integer vel fringilla quam. Fusce libero ipsum, tincidunt id quam sit amet, finibus commodo felis. Integer tempor orci in lorem varius iaculis. Fusce elementum semper nisl. Sed eu justo elit. Donec tristique augue at euismod suscipit.</p>
                        <p>In id libero sit amet magna consequat cursus vitae et eros. Integer quis magna non risus vulputate vulputate. In hac habitasse platea dictumst. Sed non gravida erat, sit amet molestie enim. Nulla aliquet dignissim nunc, vestibulum gravida mauris sollicitudin ac. Pellentesque id massa mattis, vehicula arcu id, luctus est. In magna enim, efficitur in ante et, bibendum sagittis est. In molestie gravida augue, sit amet euismod velit luctus sed. Phasellus tincidunt, augue vel consectetur rhoncus, arcu augue porta lacus, in tempor est dolor a ligula. Donec dui ipsum, porta quis auctor fermentum, posuere et est.</p>
                    </div>
                </div>
            </main>
            <Footer />            
        </div>
    );
}

export default TermsPage;