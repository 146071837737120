import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

export const PublicRoute = ({ auth, component: Component, ...rest }) => (
  <Route
    {...rest}
    component={(props) =>
      !!auth.currentUser ? (
        <Redirect
          to={
            auth.currentUser.role === 'admin'
              ? '/admin'
              : '/home'
          }
        />
      ) : (
        <Component {...props} />
      )
    }
  />
);

const mapStateToProps = ({ auth }) => ({
  auth
});

export default connect(mapStateToProps)(PublicRoute);
