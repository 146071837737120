import React from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import AdminDashboardPage from '../components/admin/AdminDashboardPage';
import UsersDashboardPage from '../components/users/UsersDashboardPage';
import AddUserPage from '../components/admin/AddUserPage';
import EditUserPage from '../components/admin/EditUserPage';
import UserProductsPage from '../components/admin/UserProductsPage';
import MediaLibrary from '../components/admin/MediaLibraryPage';
import AddProductPage from '../components/admin/AddProductPage';
import EditProductPage from '../components/admin/EditProductPage';
import LoginPage from '../components/LoginPage';
import CartPage from '../components/products/CartPage';
import AboutPage from '../components/users/AboutPage';
import ContactPage from '../components/users/ContactPage';
import NewProductPage from '../components/users/NewProductPage';
import MyAccountPage from '../components/users/MyAccountPage';
import PrivacyPage from '../components/users/PrivacyPage';
import TermsPage from '../components/users/TermsPage';
// import NotFoundPage from '../components/NotFoundPage';
import MaintenancePage from '../components/MaintenancePage'; 
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';

export const history = createBrowserHistory();

const isMaintenanceMode = false; // Replace this with your condition

const AppRouter = () => (
  <Router onUpdate={() => window.scrollTo(0, 0)} history={history}>
    <div>
      <Switch>
        {isMaintenanceMode ? (
          <Route  path="/" component={MaintenancePage} exact={true} />
        ) : (
          <>
        <PublicRoute path="/" component={LoginPage} exact={true} />
        <PrivateRoute path="/admin" component={AdminDashboardPage} />
        <PrivateRoute path="/home" component={UsersDashboardPage} />
        <PrivateRoute path="/media-library" component={MediaLibrary} />
        <PrivateRoute path="/adduser" component={AddUserPage} />
        <PrivateRoute path="/edituser/:id" component={EditUserPage} />
        <PrivateRoute path="/user-products/:id" component={UserProductsPage} />
        <PrivateRoute path="/add-product/:id" component={AddProductPage} />
        <PrivateRoute path="/edit-product/:id/product/:prodid" component={EditProductPage} />
        <PrivateRoute path="/cart" component={CartPage} />
        <PrivateRoute path="/how-does-this-work" component={AboutPage} />
        <PrivateRoute path="/contact" component={ContactPage} />
        <PrivateRoute path="/new-product" component={NewProductPage} />
        <PrivateRoute path="/my-account" component={MyAccountPage} />
        <PrivateRoute path="/privacy" component={PrivacyPage} />
        <PrivateRoute path="/terms" component={TermsPage} />
          </>
        )}        

      </Switch>
    </div>
  </Router>
);

export default AppRouter;
