import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import AdminHeader from './AdminHeader';
import ProductForm from './ProductForm';
import {
  startEditUserProduct,
  startRemoveUserProduct
} from '../../actions/userproducts';

const EditProductPage = (props) => {

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <AdminHeader />
      <main className="main-content">
        <div className="page-subheader">
          <div className="container">
            <div className="align-content">
              <h1>Edit Product - {props.product.title ? props.product.title : ''}</h1>
              <Link to={`/user-products/${props.user.id}`} className="link-button">
                <Button variant="contained" className="button">
                  Back
								</Button>
              </Link>
            </div>
          </div>
        </div>

        <div className="container">
          <ProductForm
            userproducts={props.product}
            onSubmit={(userproducts) => {
              props.startEditUserProduct(props.user.id, props.product.id, userproducts);
              props.history.push(`/user-products/${props.user.id}`);
            }}
          />
          <div className="form-button-container">
            <div className="form-button-margin">
              <Button
                variant="contained"
                color="secondary"
                className="button"
                onClick={handleClickOpen}
              >
                Remove Product
					  </Button>
            </div>
          </div>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Delete Product?"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete this product?
            </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                No
            </Button>
              <Button
                onClick={() => {
                  props.startRemoveUserProduct(props.user.id, props.product.id);
                  setOpen(false);
                  props.history.push(`/user-products/${props.user.id}`);
                }}
                color="secondary"
                autoFocus
              >
                Yes
            </Button>
            </DialogActions>
          </Dialog>
        </div>
      </main>
    </div>
  );

};

const mapStateToProps = (state, props) => {
  return {
    user: state.users.find((user) => user.id === props.match.params.id),
    product: state.userproduct.find((product) => product.id === props.match.params.prodid)
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  startEditUserProduct: (userid, id, userproducts) => {
    dispatch(startEditUserProduct(userid, id, userproducts));
  },
  startRemoveUserProduct: (userid, id) => dispatch(startRemoveUserProduct(userid, id))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditProductPage);