import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Header from './Header';
import Footer from './Footer';
import ProductSearch from '../products/ProductSearch';
import ProductList from '../products/ProductList';
import LoadingSpinner from '../LoadingSpinner';
import {
    startGetProductList
} from '../../actions/userproducts';

const UsersDashboard = (props) => {

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const loadProductList = async () => {
            await props.startGetProductList(props.auth.currentUser.uid);
            setIsLoading(false);
        };

        loadProductList();
    }, []);

    return (
        <div>
            <Header />
            <main className="main-content b-c-light-grey">
                <div className="container">
                    <div className="align-content">
                        <ProductSearch />
                    </div>
                </div>                
                <div>
                    {isLoading ? <LoadingSpinner /> : props.userproducts ? <ProductList userproducts={props.userproducts} /> : <p>Here</p>}
                </div>
            </main>
            <Footer />
        </div>
    );

};

const mapStateToProps = (state, props) => {
    return {
        auth: state.auth,
        user: state.users.find((user) => user.id === props.match.params.id),
        userproducts: state.userproduct
    };
};

const mapDispatchToProps = (dispatch) => ({
    startGetProductList: (id) => {
        dispatch(startGetProductList(id));
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UsersDashboard);