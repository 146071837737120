import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import AdminHeader from './AdminHeader';
import UserForm from './UserForm';
import { startEditUser, startRemoveUser } from '../../actions/users';

const EditUserPage = (props) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  return (
    <div>
      <AdminHeader />
      <main className="main-content">
        <div className="page-subheader">
          <div className="container">
            <div className="align-content">
              <h1>Edit User - {props.user.fullname}</h1>
              <Link to="/admin" className="link-button">
                <Button variant="contained" className="button">
                  Back
                </Button>
              </Link>
            </div>
          </div>
        </div>

        <div className="container">
          <UserForm
            user={props.user}
            isEdit
            onSubmit={(user) => {
              props.startEditUser(props.user.id, user);
              props.history.push('/admin');
            }}
          />
          <div className="form-button-container">
            <div className="form-button-margin">
              <Button
                variant="contained"
                color="secondary"
                className="button"
                onClick={handleClickOpen}
              >
                Remove User
              </Button>
            </div>
          </div>

          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Delete User?"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete this user?
            </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                No
            </Button>
              <Button 
                onClick={() => {
                  props.startRemoveUser({ id: props.user.id });
                  setOpen(false);
                  props.history.push('/admin');
                }}
                color="secondary"
                autoFocus
              >
                Yes
            </Button>
            </DialogActions>
          </Dialog>

        </div>
      </main>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    user: state.users.find((user) => user.id === props.match.params.id)
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  startEditUser: (id, user) => dispatch(startEditUser(id, user)),
  startRemoveUser: (data) => dispatch(startRemoveUser(data))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditUserPage);
