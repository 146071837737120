// User Order Reducer
const ordersReducerDefaultState = [];

export default (state = ordersReducerDefaultState, action) => {
  switch (action.type) {
    case 'ADD_USER_ORDER':
        return [...state, action.userorder];
    case 'GET_ORDER_LIST':      
      return action.userorder;
    default:
      return state;
  }
};